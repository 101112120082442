import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import BlogAuthorCard from '../components/BlogAuthorCard'
import BlogBanner from '../components/BlogBanner'
import ContentBanner from '../components/ContentBanner'
import GatsbyHead from '../components/GatsbyHead'
import LayoutWrapper from '../components/LayoutWrapper'
import MarkdownContent from '../components/MarkdownContent'

export const query = graphql`
  query ContentPage($contentful_id: String!, $locale: String!) {
    contentfulContentPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      contentful_id
      slug
      title
      node_locale
      shareable
      description {
        description
      }
      featured_image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 555)
          }
        }
      }
      content {
        childMarkdownRemark {
          htmlAst
          images {
            contentful_id
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 760)
              }
              publicURL
              extension
            }
          }
        }
      }
      author {
        id
        name
        title
        photo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 50, layout: FIXED)
            }
          }
        }
        photoLarge: photo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 100, layout: FIXED)
            }
          }
        }
        bio {
          childMarkdownRemark {
            htmlAst
            images {
              contentful_id
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 760)
                }
                publicURL
                extension
              }
            }
          }
        }
      }
    }
  }
`

const ContentPage = ({
  pageContext: { translatedPages },
  data: {
    contentfulContentPage: {
      title,
      description,
      content: {
        childMarkdownRemark: { htmlAst, images },
      },
      featured_image,
      author,
    },
  },
}) => (
  <LayoutWrapper translatedPages={translatedPages}>
    {featured_image ? (
      <BlogBanner
        featuredImage={getImage(featured_image.localFile)}
        title={title}
        description={description?.description}
        name={author?.name}
        authorTitle={author?.title}
        avatar={getImage(author?.photo.localFile)}
        contentPage
      />
    ) : (
      <ContentBanner
        title={title}
        description={description?.description}
      />
    )}
    <MarkdownContent
      el={htmlAst}
      images={images}
    />
    <BlogAuthorCard author={author} />
  </LayoutWrapper>
)

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: { canonicalUrl, translatedPages },
  data: {
    contentfulContentPage: { title, description },
  },
}) => {
  return (
    <GatsbyHead
      pageTitle={title}
      metaDescription={description?.description}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
    />
  )
}

export default ContentPage
